import { Form } from '@innedit/formidable';
import { EspaceData, UserData } from '@innedit/innedit';
import { navigate } from 'gatsby';
import React, { FC } from 'react';

import Layout from '../../components/Layout';
import CMSView from '../../components/View';
import creation from '../../params/boutique/creation.json';
import requireUser, { UserProps } from '../../utils/requireUser';

const PageEspaceCreation: FC<UserProps> = ({ user }) => {
  const handleOnSubmit = async (values: any) => {
    const userData = new UserData();
    const espaceData = new EspaceData({ params: creation });

    const boutiqueRef = await espaceData.create(values);
    const subscriptions = user.subscriptions ?? [];
    const profils = user.profils ?? [];
    subscriptions.push(boutiqueRef.id);
    profils.push('produits');
    await userData.set(user.id, {
      profils,
      subscriptions,
      espace: boutiqueRef.id,
    });

    await navigate('/boutiques/produits/');
  };

  return (
    <Layout>
      <CMSView>
        <p>
          Vous êtes sur le point de créer un nouvel espace et rien que pour
          cela, nous vous en remercions.
        </p>
        <p>
          Pour ce faire, vous devez réalisé quelques formalités pour le bon
          déroulé des futurs événements :
        </p>
        <hr className="my-6" />
        <Form
          datas={creation}
          footerProps={{ className: 'mt-12 flex justify-end' }}
          name="boutique"
          onSubmit={handleOnSubmit}
          submitProps={{
            label: 'Créer cette boutique',
          }}
        />
      </CMSView>
    </Layout>
  );
};

export default requireUser(PageEspaceCreation);
